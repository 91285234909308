var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pwd-page" }, [
    _c("div", { staticClass: "df fdc alc" }, [
      _vm.time === 1 ? _c("div", [_vm._v("请设置新支付密码")]) : _vm._e(),
      _vm.time === 2 ? _c("div", [_vm._v("请确认新支付密码")]) : _vm._e(),
      _c(
        "div",
        { staticClass: "pwd-box df fdr" },
        [
          _vm._l(6, function (i) {
            return _c("div", { staticClass: "item df alc jcc" }, [
              _vm.numbers[i - 1]
                ? _c("div", { staticClass: "point" })
                : _vm._e(),
            ])
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.numbers,
                expression: "numbers",
              },
            ],
            attrs: {
              maxlength: "6",
              type: "number",
              pattern: "\\d*",
              autofocus: "autofocus",
            },
            domProps: { value: _vm.numbers },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.numbers = $event.target.value
                },
                _vm.settingPwd,
              ],
            },
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }