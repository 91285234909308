<template>
  <div class="pwd-page">
    <div class="df fdc alc">
      <div v-if="time === 1">请设置新支付密码</div>
      <div v-if="time === 2">请确认新支付密码</div>
      <div class="pwd-box df fdr">
        <div class="item df alc jcc" v-for="i in 6">
          <div v-if="numbers[i-1]" class="point" />
        </div>
        <input
					v-model="numbers"
					maxlength="6"
					type="number"
					pattern="\d*"
					@input="settingPwd"
					autofocus="autofocus"
				/>
      </div>
    </div>

  </div>
</template>

<script>
import {editPayPass} from "@/services/my";
import md5 from "js-md5";
import setRsaPass from "@/utils/jsencrypt";
import { customAccount } from "@/utils/custom";

export default {
  name: "payPwd",
	beforeRouteEnter (to, from, next) {
		if (from.name === 'verify_phone') {
			if(sessionStorage.getItem('pwdStatus') === 'ok') {
				next();
			} else {
				history.go(-1);
			}
		} else {
			history.go(-1);
		}
	},
  data() {
    return {
      show1: false,
      numbers: '',
      time: 1,//密码设置步骤
      password: '',
      confirm_pass: '',
      verify_token:'',
			isJyFl: false //江阴福利
    }
  },
  created() {
    this.verify_token = this.$route.params.verify_token
		this.isjy()
  },
	destroyed() {
		sessionStorage.removeItem('pwdStatus');
	},
  methods: {
		//江阴福利
		isjy() {
		  if (customAccount("VUE_APP_JY")) {
		    this.isJyFl = true;
		  }
		},
		
    /**
     * 设置密码
     */
    async settingPwd() {
      let number = this.numbers
      let size = ('' + this.numbers).length
      if (size === 6 && this.time === 1) {
        this.password = md5(number);
        this.time = 2;
        this.numbers = '';
        size = 0
      }
      if (size === 6 && this.time === 2) {
        this.confirm_pass = md5(number);
        // console.log(this.password);
        // console.log(this.confirm_pass);
        try {
          const res = await editPayPass(
						this.isJyFl ? setRsaPass(this.password) : this.password,
						this.isJyFl ? setRsaPass(this.confirm_pass) : this.confirm_pass,
						this.verify_token
					);
          if (res.code === 0) {
            this.$dialog.toast({
              mes: '密码设置成功',
              icon: 'success',
              timeout: 1500
            });
            this.$store.dispatch('getUserInfo');
            this.$router.go(-2);
          } else {
            this.time = 1//密码设置步骤
            this.password = ''
            this.numbers = ''
            this.confirm_pass = ''
          }
        } catch (e) {
          console.log(e);
        }
      }

    },
  }
}
</script>

<style lang="less">
@import "../../../../common/less/variable.less";
@import "../../../../common/less/mixin.less";

.pwd-page {
  padding: 2rem 0.34rem 0 0.36rem;
  .sc(0.36rem, #333);

  .pwd-box {
    margin-top: 0.6rem;
    .wh(100%, 1rem);

    input {
      .wh(100%, 1rem);
      position: absolute;
      opacity: 0;
      left: 0;
      text-indent: -999em; /*文本向左缩进*/
      margin-left: -100%; /*输入框光标起始点向左左移*/
      width: 200%; /*输入框增大一倍*/
    }

    .item {
      flex-basis: 16.6%;
      text-align: center;
      line-height: 1rem;
      background: @color-white;;
      border: 0.01rem solid @color-row-line;

      .point {
        .wh(6px, 6px);
        border-radius: 50%;
        background: #333;
      }
    }

    .item:not(:last-of-type) {
      border-right: 0;
    }


  }
}

</style>
